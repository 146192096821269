import { LabelHTMLAttributes } from 'react'

type Custom = {
  required?: boolean
}

type LabelProps = Custom & LabelHTMLAttributes<HTMLLabelElement>

export default function Label(props: LabelProps) {
  const { children, required } = props

  return (
    <label className="block text-sm font-base font-medium  text-black-100 mb-1" {...props}>
      {children} {required && <span className="text-red-60">*</span>}
    </label>
  )
}
