'use client'
import axios, { AxiosError } from 'axios'
import { Field, Form, Formik } from 'formik'
import { signIn, useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter, useSearchParams } from 'next/navigation'
import { useState } from 'react'
import * as Yup from 'yup'
import FieldError from '@/components/FieldError'
import Input from '@/components/Input'
import Label from '@/components/Label'
import { GetProviderType } from '@/app/(protected)/api/providers/route'

const FormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').trim().lowercase().required('Email is required'),
})

export default function AdminSignInForm() {
  const [error, setError] = useState<string | null>(null)
  const [message, setMessage] = useState<string | null>(null)
  const searchParams = useSearchParams()

  const callbackUrl = searchParams?.get('callbackURL')

  const router = useRouter()
  const { data } = useSession()

  if (data?.user) {
    router.push('/')
    return null
  }

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={async values => {
        try {
          const sanitizedEmail = encodeURIComponent(values.email) //sanitize email to prevent errors
          const response = await axios.get<GetProviderType>(`/api/providers?email=${sanitizedEmail}`)

          if (!response.data) {
            setError("Sorry, we don't have this email in our system, please contact support.")
          }

          const provider = response.data
          if (provider?.authProduct && provider.authTenantId && provider?.authProviderId) {
            setMessage('Redirecting to your SSO provider...')
            setTimeout(() => {
              signIn(
                // @ts-ignore
                provider.authProviderId,
                {
                  ...(callbackUrl && { callbackUrl }),
                },
                { tenant: provider.authTenantId, product: provider?.authProduct },
              )
            }, 700)

            return
          }
          if (provider?.authProviderId) {
            provider.authProviderId !== 'google'
              ? setMessage('Redirecting to your SSO provider...')
              : setMessage('Signing in with OAuth...')

            setTimeout(() => {
              // @ts-ignore
              signIn(provider.authProviderId, {
                ...(callbackUrl && { callbackUrl }),
              })
            }, 700)
            return
          }

          console.error('Missing provider for domain', values.email)
          setError('Sorry, we don&apos;t have this email in our system, please contact support.')
        } catch (error) {
          const axiosError = error as AxiosError

          if (axiosError.response && axiosError.response.status === 404) {
            setError("Sorry, we don't have this email in our system, please contact support.")
          } else {
            console.error(axiosError)
          }
        }
      }}
      validationSchema={FormSchema}
    >
      {({ isValid, errors, touched, isSubmitting }) => (
        <>
          {message ? (
            <div>{message}</div>
          ) : (
            <Form className="mt-6">
              <Label htmlFor="email">Email</Label>
              <Field
                className="w-full rounded-lg border-gray-light"
                id="email"
                name="email"
                placeholder="jane@acme.com"
                type="email"
                component={Input}
              />
              <FieldError>{touched.email ? errors.email : null}</FieldError>

              <div className="mt-4">
                <button
                  type="submit"
                  disabled={!isValid || isSubmitting}
                  className="w-full rounded-lg border-2 border-[#D02F97] bg-[#D02F97] px-4 py-2 text-[16px] font-medium text-white hover:bg-[#ba318a] focus:outline-none"
                >
                  {isSubmitting ? 'Signing in...' : 'Sign In'}
                </button>
                <Link className="" href="/signin">
                  <button className="mt-8 w-full rounded-lg border-2 border-[#d02f98d4] px-4 py-2 text-[16px] font-semibold text-[#d02f98d4] hover:border-[#ba318a] hover:bg-[#ba318a] hover:text-white focus:outline-none">
                    Employee Sign In
                  </button>
                </Link>

                <div className="daisy-divider"></div>
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <Link className="daisy-link daisy-link-primary" href={`/signup`}>
                      Don&apos;t have an account? Sign Up
                    </Link>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <FieldError>{error}</FieldError>
              </div>
            </Form>
          )}
        </>
      )}
    </Formik>
  )
}
