import { HiOutlineExclamationCircle } from 'react-icons/hi2'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export default function FieldError(props: Props) {
  const { children } = props

  return (
    <div className={`mt-1 flex h-6 items-center text-red-70 ${!children && 'hidden'}`}>
      <HiOutlineExclamationCircle className="mr-2 h-5 w-5 text-red-70" />
      {children}
    </div>
  )
}
