import { FieldProps } from 'formik'

type Props = FieldProps<any> & { type?: string }

export default function Input(props: Props) {
  const { field, form, type = 'text', ...rest } = props
  const hasError = form?.touched[field?.name] && form?.errors[field?.name]

  return (
    <input
      type={type}
      className={`block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
        hasError && 'border-red-70'
      }`}
      {...field}
      {...rest}
    />
  )
}
